
export default {
	name: "CoreBlockFooterContactEmail",
	props: {
		email: {
			type: String,
			required: true,
		},
		site: {
			type: Object,
			required: true,
		},
	},
	setup(props) {
		return {};
	},
};
