
import { computed } from "vue";
import { renderData } from "@libraryHelpers/dataComponents.js";
export default {
	name: "CoreBlockFooterContactAddress",
	props: {
		address: {
			type: Object,
			required: true,
		},
		site: {
			type: Object,
			required: true,
		},
		teamMember: {
			type: Object,
			default: null,
		},
	},
	setup(props) {
		const streetAddress = computed(() => {
			const addressVal =
				props.teamMember?.address || props.address.streetAddress;
			return renderData(addressVal || "", props.site);
		});
		const suite = computed(() =>
			renderData(props.address.suiteNumber || "", props.site)
		);
		const city = computed(() => {
			const cityVal = props.teamMember?.city || props.address.city;
			return renderData(cityVal || "", props.site);
		});
		const state = computed(() => {
			const stateVal = props.teamMember?.state || props.address.state;
			return renderData(stateVal || "", props.site);
		});
		const zip = computed(() => {
			const zipVal = props.teamMember?.postalCode || props.address.zip;
			return renderData(zipVal || "", props.site);
		});

		const cleanAddress = () => {
			return (
				`http://maps.google.com/?q=` +
				encodeURIComponent(
					`${props.address.streetAddress} ${props.address.city} ${props.address.state} ${props.address.zip}`
				)
			);
		};
		return {
			streetAddress,
			suite,
			city,
			state,
			zip,
			cleanAddress,
		};
	},
};
